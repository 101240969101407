import counterCSS from "../../CSS/counter.module.css";
import {useCountdown} from "../../Hooks/useCountdown";
import {Fade} from "react-awesome-reveal";

const currentDate = new Date();

function getNextDateTarget() {
  const currentDate = new Date(); // Get the current date and time in UTC

  // If it's Wednesday, Thursday, or Friday before 7 AM, return the current date at 7 AM
  if (
    currentDate.getUTCDay() >= 3 &&
    currentDate.getUTCDay() <= 5 && // Wednesday to Friday
    currentDate.getUTCHours() < 7
  ) {
    const targetTime = new Date(currentDate);
    targetTime.setUTCHours(7, 0, 0, 0); // Set time to 07:00:00
    return targetTime.toISOString();
  }

  // If it's Wednesday, Thursday, or Friday between 7 AM and 8 AM, return an empty string
  if (
    currentDate.getUTCDay() >= 3 &&
    currentDate.getUTCDay() <= 5 && // Wednesday to Friday
    currentDate.getUTCHours() >= 7 &&
    currentDate.getUTCHours() < 8
  ) {
    return ""; // Return an empty string during this time
  }

  // If it's Wednesday or Thursday after 8 AM, return the next day at 7 AM
  if (
    (currentDate.getUTCDay() === 3 || currentDate.getUTCDay() === 4) && // Wednesday or Thursday
    currentDate.getUTCHours() >= 8
  ) {
    const nextDay = new Date(currentDate);
    nextDay.setUTCDate(currentDate.getUTCDate() + 1); // Move to the next day
    nextDay.setUTCHours(7, 0, 0, 0); // Set time to 07:00:00
    return nextDay.toISOString();
  }

  // If it's after Friday 8 AM, find the date of the next Wednesday 7 AM
  if (
    currentDate.getUTCDay() > 5 || // Saturday or Sunday
    (currentDate.getUTCDay() === 5 && currentDate.getUTCHours() >= 8) // Friday after 8 AM
  ) {
    const nextWednesday = new Date(currentDate);
    nextWednesday.setUTCDate(
      nextWednesday.getUTCDate() + ((3 + 7 - nextWednesday.getUTCDay()) % 7)
    ); // Calculate days until next Wednesday
    nextWednesday.setUTCHours(7, 0, 0, 0); // Set time to 07:00:00
    return nextWednesday.toISOString();
  }

  // If it's before Wednesday 7 AM, find the date of the next coming Wednesday 7 AM
  if (
    currentDate.getUTCDay() < 3 || // Monday or Tuesday
    (currentDate.getUTCDay() === 3 && currentDate.getUTCHours() < 7) // Wednesday before 7 AM
  ) {
    const nextWednesday = new Date(currentDate);
    nextWednesday.setUTCDate(
      nextWednesday.getUTCDate() + ((3 + 7 - nextWednesday.getUTCDay()) % 7)
    ); // Calculate days until next Wednesday
    nextWednesday.setUTCHours(7, 0, 0, 0); // Set time to 07:00:00
    return nextWednesday.toISOString();
  }

  // Otherwise, it's between Wednesday 8 AM and Friday 7 AM, find the date of the next Friday 7 AM
  const nextFriday = new Date(currentDate);
  nextFriday.setUTCDate(
    nextFriday.getUTCDate() + ((5 + 7 - nextFriday.getUTCDay()) % 7)
  ); // Calculate days until next Friday
  nextFriday.setUTCHours(7, 0, 0, 0); // Set time to 07:00:00
  return nextFriday.toISOString();
}

// Example usage:
// console.log(getNextDateTarget());

const Timer = ({device}) => {
  const dateToCount = getNextDateTarget();

  const [days, hours, minutes, seconds] = useCountdown(dateToCount);
  const ShowCounter = ({days, hours, minutes, seconds}) => {
    if (dateToCount!="") {
      return (
        <>
          <div className={counterCSS.show_counter}>
            {DateTimeDisplay(days, "Dias")}
            <p className={counterCSS.semicol}>:</p>
            {DateTimeDisplay(hours, "Horas")}
            <p className={counterCSS.semicol}>:</p>
            {DateTimeDisplay(minutes, "Minutos")}
            <p className={counterCSS.semicol}>:</p>
            {DateTimeDisplay(seconds, "Segundos")}
          </div>
        </>
      );
    }
  };

  const DateTimeDisplay = (value, type) => {
    return (
      <div className={counterCSS.countdown}>
        <div style={{display: "flex"}}>
          <div className={counterCSS.count_styling}>
            <p>{Math.floor(value / 10)}</p>
          </div>
          <div className={counterCSS.count_styling}>
            <p>{value % 10}</p>
          </div>
        </div>
        <span className={counterCSS.time_unit}>{type}</span>
      </div>
    );
  };

  return (
    <a
      href="https://apuestas.codere.mx/es_MX"
      rel="nofollow"
      className={counterCSS.banner_info}
    >
      {(!device && dateToCount!="")  && (
        <Fade duration={600} triggerOnce={true}>
          <div className={counterCSS.banner_title}>LA ESPERA TERMINARÁ EN:</div>
        </Fade>
      )}
      {ShowCounter({days, hours, minutes, seconds})}
    </a>
  );
};

export default Timer;
